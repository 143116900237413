@font-face {
  font-family: "Metropolis";
  src: local("Metropolis-Regular"),
    url(Metropolis-Regular.ttf) format("truetype"),
    url(Metropolis-Regular.woff2) format("woff2");
  font-weight: 400;
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis-Thin"), url(Metropolis-Thin.ttf) format("truetype"),
    url(Metropolis-Thin.woff2) format("woff2");
  font-weight: 100;
}

@font-face {
  font-family: "Metropolis";
  src: local("Metropolis-Bold"), url(Metropolis-Bold.ttf) format("truetype"),
    url(Metropolis-Bold.woff2) format("woff2");
  font-weight: 700;
}